import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    // apiKey: process.env.REACT_APP_API_KEY,
    // authDomain: process.env.zREACT_APP_AUTH_DOMAIN,
    // projectId: process.env.zREACT_APP_PROJECT_ID,
    // storageBucket: process.env.zREACT_APP_STORAGE_BUCKET,
    // messagingSenderId: process.env.zREACT_APP_MESSAGING_SENDER_ID,
    // appId: process.env.zREACT_APP_APP_ID,
    // measurementId: process.env.zREACT_APP_MEASUREMENT_ID
    apiKey: "AIzaSyCbEXbDoeqB9LX29ol0-0cA5yQPYVn93gw",
    authDomain: "hiclob-24ec5.firebaseapp.com",
    projectId: "hiclob-24ec5",
    storageBucket: "hiclob-24ec5.appspot.com",
    messagingSenderId: "345432889106",
    appId: "1:345432889106:web:3f744b246b459d2d9505cb",
    measurementId: "G-2GKCWW0QMV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);